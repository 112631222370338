@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'MTNBrighterSansBold';
  src: url('../src/assets/fonts/MTNBrighterSans-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTNBrighterSansBoldItalic';
  src: url('../src/assets/fonts/MTNBrighterSans-ExtraBoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTNBrighterSansExtraBold';
  src: url('../src/assets/fonts/MTNBrighterSans-ExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTNBrighterSansRegular';
  src: url('../src/assets/fonts/MTNBrighterSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTNBrighterSansMedium';
  src: url('../src/assets/fonts/MTNBrighterSans-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTNBrighterSansLightItalic';
  src: url('../src/assets/fonts/MTNBrighterSans-LightItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTNBrighterSansLight';
  src: url('../src/assets/fonts/MTNBrighterSans-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

